import { ColumnType } from '../../../global/table/table.types';
import { MoreInfo } from '../../../global/tooltip/common';
import React from 'react';

// MONITORING PAGE

export const MONITORING_TABLE_COLUMNS: ColumnType[] = [
  { id: 'first_name', label: 'First name', isSortable: true },
  { id: 'last_name', label: 'Last name', isSortable: true },
  { id: 'email', label: 'Email', isSortable: true },
  { id: 'organisation', label: 'Organisation', isSortable: true },
  { id: 'role', label: 'Role', isSortable: true },
  { id: 'mentor', label: 'Mentor' },
  {
    id: 'self_study',
    label: (
      <div className={'flex gap-2'}>
        % Self study
        <MoreInfo content={'% self-study completed by module'} />
      </div>
    ),
    isSortable: false,
  },
  {
    id: 'self_study2',
    label: (
      <div className={'flex gap-2'}>
        Terms
        <MoreInfo content={'% self-study completed by term'} />
      </div>
    ),
  },
  { id: 'number_of_meetings', label: 'Meetings this term', isSortable: true },
  { id: 'focus_of_the_meeting', label: 'Current focus', isSortable: true },
  { id: 'cohort', label: 'Cohort', isSortable: true },
  { id: 'actions', label: 'Info', isSortable: true },
  { id: 'last_login', label: 'Last login', isSortable: true },
];

export const STCP_COLUMNS: ColumnType[] = [
  { id: 'first_name', label: 'First name', isSortable: true },
  { id: 'last_name', label: 'Last name', isSortable: true },
  { id: 'email', label: 'Email', isSortable: true },
  {
    id: 'self_study',
    label: (
      <div className={'flex gap-2'}>
        % Self study
        <MoreInfo content={'% self-study completed by module'} />
      </div>
    ),
    isSortable: false,
  },
  { id: 'actions', label: 'Info', isSortable: true },
  { id: 'last_login', label: 'Last login', isSortable: true },
];

export const extractData = (data: any, type: string) => {
  if (type === 'module')
    return [
      {
        name: 'module_one',
        uv: data.self_studies?.module_one ?? 0,
        label: 'Module one',
        offline: data.self_studies.offline_study_module_one,
      },
      {
        name: 'module_two',
        uv: data.self_studies?.module_two ?? 0,
        label: 'Module two',
        offline: data.self_studies.offline_study_module_two,
      },
      {
        name: 'module_three',
        uv: data.self_studies?.module_three ?? 0,
        label: 'Module three',
        offline: data.self_studies.offline_study_module_three,
      },
      {
        name: 'module_four',
        uv: data.self_studies?.module_four ?? 0,
        label: 'Module four',
        offline: data.self_studies.offline_study_module_four,
      },
      {
        name: 'module_five',
        uv: data.self_studies?.module_five ?? 0,
        label: 'Module five',
        offline: data.self_studies.offline_study_module_five,
      },
      {
        name: 'module_six',
        uv: data.self_studies?.module_six ?? 0,
        label: 'Module six',
        offline: data.self_studies.offline_study_module_six,
      },
    ];
  else if (type === 'term')
    return [
      {
        name: 'term_one',
        uv: data.self_studies?.term_one ?? 0,
        label: 'Term one',
      },
      {
        name: 'term_two',
        uv: data.self_studies?.term_two ?? 0,
        label: 'Term two',
      },
      {
        name: 'term_three',
        uv: data.self_studies?.term_three ?? 0,
        label: 'Term three',
      },
    ];
};

export const NIOT_TAB = 1;
export const STCP_TAB = 2;

export const TABS = [
  {
    value: NIOT_TAB,
    label: 'Early Career Framework',
  },
  {
    value: STCP_TAB,
    label: 'School Trust CEO Programme',
  },
];

export const selectCohortOptions = [
  { label: 'Cohort 1 (2023)', value: 1 },
  { label: 'Cohort 2 (2024)', value: 2 },
];

export const meetingsData = (data: any) => {
  return [
    {
      name: 'term_one_meetings',
      uv: data?.term_one_meetings ?? 0,
      label: 'Meetings term one',
    },
    {
      name: 'term_two_meetings',
      uv: data?.term_two_meetings ?? 0,
      label: 'Meetings term two',
    },
    {
      name: 'term_three_meetings',
      uv: data?.term_three_meetings ?? 0,
      label: 'Meetings term three',
    },
  ];
};

// MEETINGS MODAL

export const MEETING_MODAL_COLUMNS: Array<ColumnType> = [
  { id: 'title', label: 'Title' },
  { id: 'scheduled_at', label: 'Scheduled at' },
  { id: 'completed_at', label: 'Completed at' },
  { id: 'action_and_focus', label: 'Action & focus' },
];

export const formatDateTime = (dateTimeStr: string) => {
  const date = new Date(dateTimeStr);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const isThereFocus = (data: any) => {
  const dataSet = data?.actions?.map((t: any) => t?.tags?.length > 0);
  return dataSet.some((value: any) => value === true);
};
