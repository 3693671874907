import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Section from '../../common/components/section';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CALENDAR_MEETINGS_AND_EVENTS, EVENTS, MEETINGS_AND_EVENTS } from '../../../service/queryKeys';
import { getMeetingsByDate } from '../../../service/api/groupsApi';
import { deleteEvent, getSharedEvents } from '../../../service/api/eventsApi';
import { Meeting } from '../../../@types/Entity/Meeting';
import { Event } from '../../../@types/Entity/Event';
import { MeetingPrerequisite } from '../../../@types/Entity/MeetingPrerequisite';
import Icon from '../../../global/icon/icon';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Me } from '../../../@types/Entity/Me';
import { niotRole, ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR } from '../../../resources/roles';
import useModal from '../../../hooks/useModal';
import EventsModal from '../../events/modals/EventsModal';
import { AddButton } from '../../../global/button/common';
import { ActionButton } from '../../../global/buttonIcon/common';

type MeetingAndEvent = {
  id: string;
  scheduledAt: string;
  title: string;
  location: string;
  prerequisites?: MeetingPrerequisite[];
  date?: string;
  startTime?: string;
  endTime?: string;
  description?: string;
  type?: string;
};

export default function MeetingsOverview({
  title,
  me,
  onboarding = false,
  finishedOnboarding = false,
}: {
  title?: string;
  me?: Me;
  onboarding?: boolean;
  finishedOnboarding?: boolean;
}) {
  const [selectedMeetingEvents, setSelectedMeetingEvents] = useState<{
    selectedDate: string | null; //Only used to change selected date styles in the calendar
    meetingsEvents: MeetingAndEvent[];
  }>({ selectedDate: null, meetingsEvents: [] });

  const [eventModal, toggleEventModal, setEventModalProps] = useModal((props: any) => (
    <EventsModal {...props} me={me} />
  ));

  const today = dayjs();
  const startOfCurrentWeek = today.startOf('week').subtract(1, 'day').startOf('day');
  const endOfNextWeek = startOfCurrentWeek.add(14, 'day').endOf('day');
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(dayjs().startOf('month'));

  const { data: meetingsAndEventsQuery, isLoading } = useQuery([MEETINGS_AND_EVENTS], () =>
    Promise.all([
      getMeetingsByDate(today.startOf('day'), endOfNextWeek.endOf('day')),
      getSharedEvents(today.startOf('day').format('YYYY/MM/DD'), endOfNextWeek.endOf('day').format('YYYY/MM/DD')),
    ])
  );

  const calendarMeetingsAndEventsQuery = useQuery(
    [CALENDAR_MEETINGS_AND_EVENTS, { month: calendarSelectedDate.format('YYYY/MM') }],
    () =>
      Promise.all([
        getMeetingsByDate(calendarSelectedDate, calendarSelectedDate.endOf('month')),
        getSharedEvents(
          calendarSelectedDate.format('YYYY/MM/DD'),
          calendarSelectedDate.endOf('month').format('YYYY/MM/DD')
        ),
      ])
  );

  const calendarMeetingsAndEvents =
    calendarMeetingsAndEventsQuery.data?.reduce<MeetingAndEvent[]>((acc, curr, index) => {
      if (index === 0) {
        const data = (curr.data.data as Meeting[]).map((meeting) => ({
          id: meeting.id,
          scheduledAt: meeting.scheduledAt,
          title: meeting.title,
          location: meeting.location,
          prerequisites: meeting.prerequisites,
        }));

        return [...acc, ...data];
      }

      const data = (curr.data.data as Event[]).map((event) => ({
        id: event.id,
        // Used to compare it with the meetings
        scheduledAt: dayjs(`${event.date} ${event.start_time}`, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm'),
        date: event.date,
        startTime: event.start_time,
        endTime: event.end_time,
        title: event.title,
        location: event.location,
        description: event.description,
      }));

      return [...acc, ...data];
    }, []) ?? [];

  const meetingAndEvents =
    meetingsAndEventsQuery
      ?.reduce<MeetingAndEvent[]>((acc, curr, index) => {
        if (index === 0) {
          const data = (curr.data.data as Meeting[]).map((meeting) => ({
            id: meeting.id,
            scheduledAt: meeting.scheduledAt,
            title: meeting.title,
            location: meeting.location,
            prerequisites: meeting.prerequisites,
            type: 'meeting',
          }));

          return [...acc, ...data];
        }

        const data = (curr.data.data as Event[]).map((event) => ({
          id: event.id,
          // Used to compare it with the meetings
          scheduledAt: dayjs(`${event.date} ${event.start_time}`, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm'),
          date: event.date,
          startTime: event.start_time,
          endTime: event.end_time,
          title: event.title,
          location: event.location,
          description: event.description,
          type: 'event',
        }));

        return [...acc, ...data];
      }, [])
      ?.sort((a, b) => new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime()) ?? [];

  let lastPrintedDay: string | null = null;

  const queryClient = useQueryClient();

  const deleteEventMutation = useMutation(deleteEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries([EVENTS]);
      queryClient.invalidateQueries([MEETINGS_AND_EVENTS]);
      queryClient.invalidateQueries([CALENDAR_MEETINGS_AND_EVENTS]);
    },
  });

  useEffect(() => {
    if (meetingAndEvents.length > 0 && !isLoading)
      setSelectedMeetingEvents({
        selectedDate: null,
        meetingsEvents: [meetingAndEvents[0]],
      });
  }, [isLoading]);

  const userTag = me ? niotRole(me) : undefined;

  return (
    <>
      {eventModal}
      {title && <h2>{title}</h2>}

      <Section size={'md'} className={'mt-5'}>
        <div className={'xl:grid xl:grid-cols-3 xl:gap-4'}>
          <div>
            <Calendar
              calendarType={'US'}
              tileClassName={({ date, view }) => {
                if ('month' !== view) {
                  return null;
                }

                const calendarDay = dayjs(date);

                const classNames = [];

                if (selectedMeetingEvents.selectedDate === calendarDay.format('YYYY/MM/DD')) {
                  classNames.push('text-warning font-bold');
                }

                if (
                  calendarMeetingsAndEvents.some(
                    (meetingEvent) =>
                      dayjs(meetingEvent.scheduledAt).format('YYYY/MM/DD') === calendarDay.format('YYYY/MM/DD')
                  )
                ) {
                  classNames.push('bg-primary');
                } else if (calendarDay > startOfCurrentWeek && calendarDay < endOfNextWeek) {
                  classNames.push('bg-primary', 'bg-opacity-50');
                } else {
                  classNames.push('bg-white');
                }

                if (calendarDay.format('YYYY/MM/DD') === today.format('YYYY/MM/DD')) {
                  classNames.push('font-bold');
                }

                return classNames;
              }}
              onActiveStartDateChange={({ activeStartDate }) => setCalendarSelectedDate(dayjs(activeStartDate!))}
              onClickDay={(value) => {
                const date = dayjs(value).format('YYYY/MM/DD');

                setSelectedMeetingEvents({
                  selectedDate: date,
                  meetingsEvents: calendarMeetingsAndEvents.filter(
                    (meetingAndEvents: MeetingAndEvent) =>
                      dayjs(meetingAndEvents.scheduledAt).format('YYYY/MM/DD') === date
                  ),
                });
              }}
            />
            <div className={'mt-5 flex justify-start'}>
              <div className={'justify-self-start '}>
                {(onboarding || finishedOnboarding) &&
                  me &&
                  [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && (
                    <AddButton
                      label={'Add school event'}
                      onClick={() => {
                        setEventModalProps({
                          title: 'Add school event',
                        });
                        toggleEventModal(true);
                      }}
                    />
                  )}
              </div>
            </div>
          </div>

          <div className={'mt-10 xl:mt-0'}>
            <h5>Upcoming</h5>
            <div className={'mt-2'}>
              <div className={'grid grid-cols-12'}>
                {0 === (meetingAndEvents.length ?? 0) && (
                  <div className={'col-span-12 italic'}>
                    Details of meetings {['null', undefined].includes(userTag) ? 'and whole school sessions' : ''} will
                    display here
                  </div>
                )}
                {meetingAndEvents.map((meetingEvent) => {
                  const meetingDate = dayjs(meetingEvent.scheduledAt);
                  const meetingFormattedDate = dayjs(meetingEvent.scheduledAt).format('YYYY-MM-DD');

                  const component = (
                    <React.Fragment key={meetingEvent.id}>
                      <div className={'col-span-2 mt-2 mr-2 text-center'}>
                        {meetingFormattedDate !== lastPrintedDay && (
                          <>
                            <p className={'text-sm'}>{meetingDate.format('ddd')}</p>
                            <p className={'text-2xl font-bold'}>{meetingDate.format('DD')}</p>
                          </>
                        )}
                      </div>

                      <div
                        className={`col-span-10 cursor-pointer mt-2 ${
                          me?.role === ROLE_SCHOOL_COORDINATOR && (onboarding || finishedOnboarding)
                            ? 'grid lg:grid-cols-2 gap-2'
                            : ''
                        }`}
                        onClick={() =>
                          setSelectedMeetingEvents({
                            selectedDate: null,
                            meetingsEvents: [meetingEvent],
                          })
                        }
                      >
                        <div>
                          <p>{meetingEvent.title}</p>
                          <p className={'text-sm'}>
                            {dayjs(meetingEvent.scheduledAt).format('HH:mm')}
                            {meetingEvent.endTime ? ` - ${meetingEvent.endTime}` : ''} ・ {meetingEvent.location}
                          </p>
                        </div>

                        {me?.role === ROLE_SCHOOL_COORDINATOR &&
                          (onboarding || finishedOnboarding) &&
                          meetingEvent.type === 'event' && (
                            <ul className={'actions-list flex'}>
                              <li>
                                <ActionButton.Edit
                                  archived={false}
                                  onClick={() => {
                                    setEventModalProps({
                                      title: 'Edit event',
                                      event: {
                                        ...meetingEvent,
                                        date: meetingEvent.date
                                          ? dayjs(meetingEvent.date, 'DD/MM/YYYY').toDate()
                                          : undefined,
                                        start_time: meetingEvent.startTime
                                          ? dayjs(meetingEvent.startTime, 'HH:mm').toDate()
                                          : undefined,
                                        end_time: meetingEvent.endTime
                                          ? dayjs(meetingEvent.endTime, 'HH:mm').toDate()
                                          : undefined,
                                      },
                                    });
                                    toggleEventModal(true);
                                  }}
                                />
                              </li>
                              <li>
                                <ActionButton.Remove
                                  archived={false}
                                  onClick={() => {
                                    deleteEventMutation.mutate(meetingEvent.id);
                                  }}
                                />
                              </li>
                            </ul>
                          )}
                      </div>
                    </React.Fragment>
                  );

                  lastPrintedDay = meetingFormattedDate;

                  return component;
                })}
              </div>
            </div>
          </div>

          {(selectedMeetingEvents?.meetingsEvents?.length ?? 0) > 0 && (
            <div className={'mt-6 xl:mt-0'}>
              {selectedMeetingEvents?.meetingsEvents?.map((selectedMeetingEvent, index) => (
                <div key={selectedMeetingEvent.id} className={'border-2 border-gray-200 rounded p-2 mb-2'}>
                  <div className={'flex justify-between'}>
                    <p>{selectedMeetingEvent.title}</p>
                    <Icon
                      tooltipText={'Close'}
                      onClick={() =>
                        setSelectedMeetingEvents((state) => ({
                          ...state,
                          meetingsEvents: state.meetingsEvents.filter((item) => item.id !== selectedMeetingEvent.id),
                        }))
                      }
                      icon={'XSquare'}
                      color={'grey'}
                      elementSize={20}
                      className={'cursor-pointer'}
                      style={{ padding: 0, margin: 0 }}
                    />
                  </div>
                  <p className={'text-sm'}>
                    {dayjs(selectedMeetingEvent.scheduledAt).format('HH:mm')}
                    {selectedMeetingEvent.endTime ? ` - ${selectedMeetingEvent.endTime}` : ''} ・{' '}
                    {selectedMeetingEvent.location}
                  </p>
                  {selectedMeetingEvent.description !== undefined ? (
                    <Editor
                      readOnly={true}
                      toolbarHidden={true}
                      editorState={EditorState.createWithContent(
                        convertFromRaw(JSON.parse(selectedMeetingEvent.description))
                      )}
                    />
                  ) : (
                    <ul className={'list-disc list-inside'}>
                      {selectedMeetingEvent.prerequisites?.map((prerequisite) => (
                        <li key={prerequisite.id} className={'text-sm'}>
                          {prerequisite.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Section>
    </>
  );
}
