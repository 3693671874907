import React from 'react';
import VideoSection from '../sections/videoSection';
import { Section } from '../../common';
import { SchoolSummary } from '../sections';
import PlanTeacherDashboard from '../../organisation/sections/planTeacherDashboard';
import DashboardWebinar from '../../zoom/components/dashboardWebinar';
import { CommunityNewsfeed } from '../../index';
import OnboardingDashboard from './onboardingDashboard';
import { ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, RolesType } from '../../../resources/roles';
import { Me } from '../../../@types/Entity/Me';
import MeetingsOverview from '../../greatTeachingTeams/components/MeetingsOverview';

const SchoolDashboard = ({
  data,
  name,
  email,
  onboarding = false,
  role,
  registrationLink,
  me,
  finishedOnboarding = false,
}: {
  data: any;
  name: string;
  email: string;
  onboarding?: boolean;
  role?: RolesType;
  registrationLink?: string;
  me: Me;
  finishedOnboarding?: boolean;
}) => {
  return (
    <>
      {onboarding ? (
        <>
          <OnboardingDashboard
            link={role === ROLE_SCHOOL_COORDINATOR ? registrationLink : ''}
            me={me}
            type={
              role === ROLE_SCHOOL_COORDINATOR
                ? 'school'
                : role === ROLE_ASSISTANT_GT_COORDINATOR
                ? 'teacher'
                : undefined
            }
          />
        </>
      ) : (
        <>
          <div className={'block-with-sub-container mb-5'}>
            <MeetingsOverview me={me} finishedOnboarding={finishedOnboarding} />
          </div>
          {data.zoom && <DashboardWebinar webinarData={data.zoom} name={name} email={email} />}
          <Section
            className={'field-mb'}
            headline={
              <div>
                Current school information
                <div className={'text-sm'}>
                  {data?.academic_year ? (
                    `${data.academic_year.start_year}-${data.academic_year.end_year}`
                  ) : (
                    <i>No academic year set</i>
                  )}
                </div>
                <div className={'text-sm'}>{data?.development_period?.name}</div>
              </div>
            }
          >
            {data?.welcome ? <VideoSection video={data.welcome} /> : <SchoolSummary data={data} />}
            <PlanTeacherDashboard isCoordinator={false}></PlanTeacherDashboard>
          </Section>
          <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 gap-8 field-mb">
            <CommunityNewsfeed width={'lg'} />
            {data.tutorial && (
              <Section size={null} headline={'Tutorial'} className={'w-full lg:w-2/4'}>
                <VideoSection video={data.tutorial} />
              </Section>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SchoolDashboard;
